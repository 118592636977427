import React from 'react';
import { Link } from 'gatsby';

const Header: React.FC = () => {
  return (
    <header>
      <div className="container">
        <div>
          <h1>
            <Link to="/">Zak Laughton</Link>
          </h1>
        </div>
        <nav>
          <Link to="/" activeClassName="active">
            About
          </Link>
          <Link to="/blog" activeClassName="active" partiallyActive={true}>
            Blog
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
